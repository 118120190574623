
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
  'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
  'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
          from 180deg at 50% 50%,
          #16abff33 0deg,
          #0885ff33 55deg,
          #54d6ff33 120deg,
          #0071ff33 160deg,
          transparent 360deg
  );
  --secondary-glow: radial-gradient(
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
          #00000080,
          #00000040,
          #00000030,
          #00000020,
          #00000010,
          #00000010,
          #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
            to bottom right,
            rgba(1, 65, 255, 0),
            rgba(1, 65, 255, 0),
            rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
            #ffffff80,
            #ffffff40,
            #ffffff30,
            #ffffff20,
            #ffffff10,
            #ffffff10,
            #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
          to bottom,
          transparent,
          rgb(var(--background-end-rgb))
  )
  rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.container {
  padding-top: 30px;
  padding-bottom: 100px;
  max-width: 1024px;
  margin: auto;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
}

.inputContainer {
  margin-bottom: 20px;
}

.submit {
  padding: 5px;
}

.item {
  display: flex;
  border: 2px solid gray;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.image {
  display: flex;
  margin-right: 10px;
  flex: 1;
}

.image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-holder {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.action-button {
  padding: 6px;
  background-color: #6a0bf7;
  font-size: 12px;
  border-radius: 10px;
  border: 2px solid transparent;
}

.action-button-success {
  background-color: #1dbd1e;
}

.action-button:hover {
  cursor: pointer;
  background-color: #7d2cf6;
}

.action-button-success:hover {
  background-color: #21d822;
}

.navbar {
  display: flex;
  font-size: 20px;
}

.navbar > div {
  margin-right: 15px;
}

.navbar > div:hover {
  cursor: pointer;
  text-decoration: underline;
}

.navbar-container {
  margin-bottom: 20px;
}

.user-account {
  margin-top: 5px;
}

.link {
  text-decoration: underline;
}

.message-warning {
  padding: 5px;
  max-width: 300px;
  color: black;
  background-color: #fffa45;
  border-radius: 5px;
}

button:disabled {
  cursor: not-allowed !important;
  background-color: gray !important;
}
